




















import GetActivity from "@/models/Activities/GetActivity";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    activity: {
      type: GetActivity,
      required: true,
    },
  },
});
