import { JsonObject, JsonProperty } from "json2typescript";
import GetActivity from "../Activities/GetActivity";

@JsonObject("AthleteSummary")
export default class AthleteSummary {
  @JsonProperty("id")
  id: number;
  @JsonProperty("firstname")
  firstname: string;
  @JsonProperty("lastname")
  lastname: string;
  @JsonProperty("activities_count")
  activitiesCount: number;
  @JsonProperty("profile")
  profile: string;

  constructor() {
    this.id = 0;
    this.firstname = "";
    this.lastname = "";
    this.activitiesCount = 0;
    this.profile = "";
  }

  public get fullname() {
    return `${this.firstname} ${this.lastname}`;
  }

  public get initials() {
    const first = this.firstname.substring(0,1);
    const last = this.lastname.substring(0,1);
    return first + last;
  }
}
