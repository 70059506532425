import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import OAuthApi from "@/api/OAuthApi";
import BaseApi from "@/api/BaseApi"
import AthletesApi from "@/api/AthletesApi";
import Athlete from "@/models/Athletes/Athlete"
import { ActivitiesModule } from "./activities";
import { ApplicationModule } from "./application";
import AthleteSummary from "@/models/Athletes/AthleteSummary";
export interface IUserState {
  accessToken: string;
  refreshToken: string;
  athlete: Athlete;
}

@Module({ dynamic: true, store, name: "User" })
class User extends VuexModule implements IUserState {
  public accessToken: string = localStorage.getItem("Access-Token") || "";
  public refreshToken: string = localStorage.getItem("Refresh-Token") || "";
  public athleteId = localStorage.getItem("Athlete-Id") || "";
  public athlete = new Athlete();
  public otherAthletes: AthleteSummary[] = [];

  private expiresAt: string = localStorage.getItem("Expires-At") || "";

  @Mutation
  private setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
    localStorage.setItem("Access-Token", accessToken);
    BaseApi.refreshAuthenticatedStravaApi();
  }

  @Mutation
  private setRefreshToken(refreshToken: string) {
    this.refreshToken = refreshToken;
    localStorage.setItem("Refresh-Token", refreshToken);
  }

  @Mutation
  private setAthlete(athlete: Athlete) {
    this.athlete = athlete;
    this.athleteId = athlete.id.toString();
    localStorage.setItem("Athlete-Id", athlete.id.toString());
  }

  @Mutation
  private setExpiresAt(expiresAt: string) {
    this.expiresAt = expiresAt;
    localStorage.setItem("Expires-At", expiresAt);
  }

  @Mutation
  private setOtherAthletes(athletes: AthleteSummary[]) {
    const other = athletes.filter(a => a.id != this.athlete.id)    ;

    this.otherAthletes = other;
  }

  @Action({ rawError: true })
  public async exchangeToken(code: string) {
    ApplicationModule.setLoading("Getting access token..");
    const oauthResponse = await OAuthApi.exchangeToken(code);

    this.setAccessToken(oauthResponse.accessToken);
    this.setRefreshToken(oauthResponse.refreshToken);
    this.setExpiresAt(oauthResponse.expiresAt.toString());
    ApplicationModule.setLoading("Fetching activities from Strava..");
    await ActivitiesModule.fetchActivitiesFromStrava();
    const athlete = oauthResponse.athlete;
    athlete.activities.push(...ActivitiesModule.activities);
    this.setAthlete(athlete);
    ApplicationModule.setLoading("Registering Athlete in backend..");
    await AthletesApi.registerAthlete(athlete, this.accessToken, +this.expiresAt, this.refreshToken);
    ApplicationModule.stopLoading();
    ApplicationModule.initialize();
  }

  @Action({ rawError: true })
  public async retrieveAthlete() {
    ApplicationModule.setLoading("Fetching athlete..");
    if (this.athleteId) {
      const athlete = await AthletesApi.getAthlete(+this.athleteId)
      this.setAthlete(athlete);
      ActivitiesModule.setActivities(athlete.activities);
    }
    ApplicationModule.stopLoading();
  }

  @Action({ rawError: true })
  public async refreshAccessToken() {
    const oauthResponse = await OAuthApi.refreshToken(this.refreshToken);

    this.setAccessToken(oauthResponse.accessToken);
    this.setRefreshToken(oauthResponse.refreshToken);
    this.setExpiresAt(oauthResponse.expiresAt.toString());
  }

  @Action({ rawError: true })
  public async retrieveOtherAthletes() {
    ApplicationModule.setLoading("Fetching other athletes..");
    const athletes = await AthletesApi.getAthletesSummaries();
    this.setOtherAthletes(athletes);
    ApplicationModule.stopLoading();
}

  public get isAuthenticated() {
  return !!this.accessToken;
}

  public get isTokenExpired() {
  if (!this.expiresAt) return true;
  const now = (Date.now() / 1000) | 0; // Date.now donne le nombre de millisecondes, tandis qu'on a en secondes pour l'expiresAt
  return +this.expiresAt < now;
}

  public get myActivitiesCount() {
  return this.athlete.activities.length;
}

  public get myLastActivities() {
  if (this.athlete.activities)
    return this.athlete.activities.slice(-3).reverse();
  else return [];
}
}

export const UserModule = getModule(User);
