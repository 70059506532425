







import { ApplicationModule } from "@/store/modules/application";
import { Vue, Component } from "vue-property-decorator";

@Component({
  name: "LoadingIndicator",
})
export default class extends Vue {
  get isAppLoading() {
    return ApplicationModule.isLoading;
  }
  get loadingMessage() {
    return ApplicationModule.loadingMessage;
  }
}
