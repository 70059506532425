import Ranking from "@/models/Rankings/Ranking";
import { JsonConvert } from "json2typescript";
import api from "./BaseApi";

export default abstract class RankingApi {
  static async getRanking(
    startDate?: string,
    endDate?: string,
    weekModifier?: string
  ): Promise<Ranking> {
    const config = {
      params: {
        startDate: startDate,
        endDate: endDate,
        weekModifier: weekModifier,
      },
    };

    const response = await api.backendApi.get<Ranking>(`/rankings`, config);

    return new JsonConvert().deserializeObject(response.data, Ranking);
  }
}
