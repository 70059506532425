













import { Vue, Component } from "vue-property-decorator";
import NavBar from "@/components/layout/NavBar.vue";
import LoadingIndicator from "@/components/layout/LoadingIndicator.vue"
import ErrorSnackbar from "@/components/layout/ErrorSnackbar.vue";

@Component({
  name: "Default",
  components: { NavBar, LoadingIndicator, ErrorSnackbar },
})
export default class extends Vue {
  
}
