import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Activity")
export default class PostActivity {
  @JsonProperty("id", Number)
  id = 0;
  @JsonProperty("athlete_id", Number)
  athleteId = 0;
  @JsonProperty("name", String)
  name = '';
  @JsonProperty("distance", Number)
  distance = 0;
  @JsonProperty("moving_time", Number)
  movingTime = 0;
  @JsonProperty("elapsed_time", Number)
  elapsedTime = 0;
  @JsonProperty("total_elevation_gain", Number)
  totalElevationGain = 0;
  @JsonProperty("type", String)
  type = "";
  @JsonProperty("start_date_local", String)
  startDate = "";
  @JsonProperty("average_speed", Number)
  averageSpeed = 0;
  @JsonProperty("max_speed", Number)
  maxSpeed = 0;
}
