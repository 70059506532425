import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule,
  } from "vuex-module-decorators";
  import store from "@/store/index";
  import { UserModule } from "@/store/modules/user";
  export interface IApplicationState {
      initialized: boolean;
      isLoading: boolean;
      loadingMessage: string;
      errorMessage: string;
  }
  
  @Module({ dynamic: true, store, name: "Application" })
  class Application extends VuexModule implements IApplicationState {
    initialized = false;
    isLoading = false;
    loadingMessage = "";
    errorMessage = "";

    @Action({rawError: true})
    public async initApp() {
        await UserModule.retrieveAthlete();
        await UserModule.retrieveOtherAthletes();
        this.initialize();
    }

    @Mutation
    public setLoading(message: string){
      this.isLoading = true;
      this.loadingMessage = message;
    }

    @Mutation
    public stopLoading() {
      this.isLoading = false;
      this.loadingMessage = "";
    }

    @Mutation
    public initialize() {
      this.initialized = true;
    }

    @Mutation
    public displayError(message: string){
      this.errorMessage = message;
      setTimeout(() => {
        this.errorMessage = "";
      }, 5000);
    }
  }
  
  export const ApplicationModule = getModule(Application);
  