import { UserModule } from "@/store/modules/user";
import axios, { AxiosInstance } from "axios";

export default class AxiosApi {
  private static _unauthenticatedStravaApi: AxiosInstance | null;
  static get unauthenticatedStravaApi() {
    if (!this._unauthenticatedStravaApi) {
      this._unauthenticatedStravaApi = axios.create();
    }
    return this._unauthenticatedStravaApi;
  }

  private static _authenticatedStravaApi: AxiosInstance | null;
  static get authenticatedStravaApi() {
    if (!this._authenticatedStravaApi) {
      this._authenticatedStravaApi = axios.create(
        {
          baseURL: "https://www.strava.com/api/v3/",
          headers: {Authorization: `Bearer ${UserModule.accessToken}`}
        }
      );
    }
    return this._authenticatedStravaApi;
  }

  public static refreshAuthenticatedStravaApi() {
    this._authenticatedStravaApi = axios.create(
      {
        baseURL: "https://www.strava.com/api/v3/",
        headers: {Authorization: `Bearer ${UserModule.accessToken}`}
      }
    );
  }

  private static _backendApi: AxiosInstance | null;
  static get backendApi() {
    if (!this._backendApi) {
      this._backendApi = axios.create(
        {
          baseURL: process.env.VUE_APP_API_URL + "/api",
        }
      );
    }
    return this._backendApi;
  }
}
