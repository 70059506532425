import Vue from "vue";
import Vuex from "vuex";
import { IUserState } from "./modules/user";
import {IActivitiesState} from './modules/activities'
import {IApplicationState} from './modules/application'

Vue.use(Vuex);

export interface IRootState {
  user: IUserState;
  activities: IActivitiesState;
  application: IApplicationState;
}

export default new Vuex.Store<IRootState>({});
