import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import BaseApi from "@/api/BaseApi"
import GetActivity from "@/models/Activities/GetActivity";
import ActivitiesApi from "@/api/ActivitiesApi";
import { JsonConvert } from "json2typescript";
import PostActivity from "@/models/Activities/PostActivity";
export interface IActivitiesState {
  activities: GetActivity[];
}

@Module({ dynamic: true, store, name: "Activities" })
class Activities extends VuexModule implements IActivitiesState {
  public activities: GetActivity[] = [];

  private PER_PAGE = 30;

  @Mutation
  public setActivities(activities: GetActivity[]) {
    this.activities = activities;
  }

  @Action({ rawError: true })
  public async fetchActivitiesFromStrava() {
    let page = 1;
    const activities: GetActivity[] = [];
    while (page != -1) {
      await ActivitiesApi.getAthleteActivitiesFromStrava(page, this.PER_PAGE).then((activitiesFetched) => {
        activities.push(...activitiesFetched);
        activitiesFetched.length < this.PER_PAGE ? page = -1 : page++;
      });
    }
    this.setActivities(activities);
  }
}

export const ActivitiesModule = getModule(Activities);
