import RankingLine from '@/models/Rankings/RankingLine'
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject("Ranking")
export default class Ranking {

    @JsonProperty("start_date", String)
    startDate = "";
    @JsonProperty("end_date", String)
    endDate = "";
    @JsonProperty("lines", [RankingLine])
    lines: RankingLine[] = [];

    get startDateDate(): string {
        const date = new Date(this.startDate);

        return date.toLocaleDateString('fr-be')
    }
    
    get endDateDate(): string {
        const date = new Date(this.endDate);

        return date.toLocaleDateString('fr-be')
    }
}
