












import { Vue, Component } from "vue-property-decorator";
import NavBar from "@/components/layout/NavBar.vue";
import ErrorSnackbar from "@/components/layout/ErrorSnackbar.vue";

@Component({
  name: "UnauthenticatedLayout",
  components: { NavBar, ErrorSnackbar },
})
export default class UnauthenticatedLayout extends Vue {}
