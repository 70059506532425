import { JsonObject, JsonProperty } from "json2typescript";
import GetActivity from "../Activities/GetActivity";

@JsonObject("Athlete")
export default class Athlete {
  @JsonProperty("id")
  id: number;
  @JsonProperty("username")
  username: string;
  @JsonProperty("firstname")
  firstname: string;
  @JsonProperty("lastname")
  lastname: string;
  @JsonProperty("profile_medium")
  profile: string;

  activities: GetActivity[] = [];

  constructor() {
    this.id = 0;
    this.username = "";
    this.firstname = "";
    this.lastname = "";
    this.profile = "";
  }

  public get fullname() {
    return `${this.firstname} ${this.lastname}`;
  }

  public get initials() {
    const first = this.firstname.substring(0,1);
    const last = this.lastname.substring(0,1);
    return first + last;
  }
}
