import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("RankingLine")
export default class RankingLine {
  @JsonProperty("athlete_name")
  public athleteName = "";
  @JsonProperty("total_distance")
  public totalDistance = 0;
  @JsonProperty("total_moving_time")
  public totalMovingTime = 0;
  @JsonProperty("activities_count")
  public activitiesCount = 0;

  get convertedTime(): string {
    let s = this.totalMovingTime;
    const secs = s % 60;
    s = (s - secs) / 60;
    const mins = s % 60;
    const hrs = (s - mins) / 60;

    return (
      hrs +
      ":" +
      mins.toString().padStart(2, "0") +
      ":" +
      secs.toString().padStart(2, "0")
    );
  }

  get distanceInKilometers() {
    const distance: number = this.totalDistance / 1000;
    return distance.toFixed(2);
  }
}
