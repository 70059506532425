








import { Component, Vue } from "vue-property-decorator";
import { ApplicationModule } from "./store/modules/application";
import DefaultLayout from '@/layouts/Default.vue'
import UnauthenticatedLayout from '@/layouts/Unauthenticated.vue'

const default_layout = "default";

@Component({
  name: "App",
  components: {DefaultLayout, UnauthenticatedLayout},
})
export default class AppVue extends Vue {
  get layout() {
    return (this.$route.meta?.layout || default_layout) + '-layout';
  }

  created() {
    ApplicationModule.initApp();
  }  
}
