import { JsonObject, JsonProperty } from "json2typescript";
import Athlete from "../Athletes/Athlete";

@JsonObject("OAuthResponse")
export class OAuthResponse {
  @JsonProperty("token_type")
  tokenType: string;
  @JsonProperty("expires_at")
  expiresAt: number;
  @JsonProperty("expires_in")
  expiresIn: number;
  @JsonProperty("refresh_token")
  refreshToken: string;
  @JsonProperty("access_token")
  accessToken: string;
  @JsonProperty("athlete", Athlete)
  athlete: Athlete;

  constructor() {
    this.tokenType = "";
    this.accessToken = "";
    this.refreshToken = "";
    this.expiresAt = 0;
    this.expiresIn = 0;
    this.athlete = new Athlete();
  }
}
