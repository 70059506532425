import { JsonObject, JsonProperty } from "json2typescript";
import GetActivity from "../Activities/GetActivity";
import Athlete from "./Athlete";

@JsonObject("BackendAthlete")
export default class BackendAthlete {
  @JsonProperty("id")
  id = 0;
  @JsonProperty("username")
  username = '';
  @JsonProperty("firstname")
  firstname = '';
  @JsonProperty("lastname")
  lastname = '';
  @JsonProperty("profile_medium")
  profile = '';
  @JsonProperty("activities", [GetActivity])
  activities: GetActivity[] = [];

  public get fullname() {
    return `${this.firstname} ${this.lastname}`;
  }

  public get initials() {
    const first = this.firstname.substring(0,1);
    const last = this.lastname.substring(0,1);
    return first + last;
  }
}
