











































import ActivityCard from "@/components/activities/ActivityCard.vue";
import AthleteCard from "@/components/athletes/AthleteCard.vue";
import RankingCard from "@/components/rankings/RankingCard.vue";
import { UserModule } from "@/store/modules/user";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { ActivityCard, AthleteCard, RankingCard },
})
export default class Home extends Vue {
  get myActivitiesCount() {
    return UserModule.myActivitiesCount;
  }

  get myLastActivities() {
    return UserModule.myLastActivities;
  }

  get otherAthletes() {
    return UserModule.otherAthletes;
  }
}
