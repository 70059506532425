import Athlete from "@/models/Athletes/Athlete";
import AthleteSummary from "@/models/Athletes/AthleteSummary";
import BackendAthlete from "@/models/Athletes/BackendAthlete";
import RegisterAthlete from "@/models/Athletes/RegisterAthlete";
import { JsonConvert } from "json2typescript";
import api from "./BaseApi";

export default abstract class AthletesApi {
    static async getAthletesSummaries(): Promise<AthleteSummary[]> {
        const response = await api.backendApi.get<AthleteSummary[]>('/athletes');

        return response.data.map((a) => new JsonConvert().deserializeObject(a, AthleteSummary));
    }

    static async getAthlete(id: number): Promise<BackendAthlete> {
        const response = await api.backendApi.get<BackendAthlete>(`/athletes/${id}`)

        return new JsonConvert().deserializeObject(response.data, BackendAthlete);
    }

    static async registerAthlete(athlete: Athlete, accessToken: string, expiresAt: number, refreshToken: string): Promise<void> {
        const toSerialize = {
            ...athlete,
            accessToken: accessToken,
            expiresAt: expiresAt,
            refreshToken: refreshToken
        };
        const serializedAthlete = new JsonConvert().serializeObject(toSerialize, RegisterAthlete);
        const response = await api.backendApi.post('/athletes', serializedAthlete);

        console.log(response.data);
    }
}
