





















import AthleteSummary from "@/models/Athletes/AthleteSummary";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    athlete: {
      type: AthleteSummary,
      required: true,
    },
  },
});
