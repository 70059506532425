import { OAuthRefreshResponse } from "@/models/OAuth/OAuthRefreshResponse";
import { OAuthResponse } from "@/models/OAuth/OAuthResponse";
import { JsonConvert } from "json2typescript";
import api from "./BaseApi";

export default abstract class OAuthApi {
  static async exchangeToken(code: string): Promise<OAuthResponse> {
    const data = {
      client_id: 69124,
      client_secret: "f7d65b92f33e1f689311a21439e3f42590d79f2a",
      code: code,
      grant_type: "authorization_code",
    };

    const response = await api.unauthenticatedStravaApi.post<OAuthResponse>(
      "https://www.strava.com/oauth/token",
      data
    );
    
    return new JsonConvert().deserializeObject(response.data, OAuthResponse);
  }

  static async refreshToken(token: string): Promise<OAuthRefreshResponse> {
    const data = {
      client_id: 69124,
      client_secret: "f7d65b92f33e1f689311a21439e3f42590d79f2a",
      refresh_token: token,
      grant_type: "refresh_token",
    };

    const response = await api.unauthenticatedStravaApi.post<OAuthRefreshResponse>(
      "https://www.strava.com/oauth/token",
      data
    );

    return new JsonConvert().deserializeObject(response.data, OAuthRefreshResponse);
  }
}
