import { JsonObject, JsonProperty } from "json2typescript";
import GetActivity from "../Activities/GetActivity";

@JsonObject("RegisterAthlete")
export default class RegisterAthlete {
    @JsonProperty("id")
    id = 0;
    @JsonProperty("username")
    username = '';
    @JsonProperty("firstname")
    firstname = '';
    @JsonProperty("lastname")
    lastname = '';
    @JsonProperty("profile_medium")
    profile = '';
    @JsonProperty("activities", [GetActivity])
    activities: GetActivity[] = [];
    @JsonProperty("access_token")
    accessToken = '';
    @JsonProperty("expires_at")
    expiresAt = 0;
    @JsonProperty("refresh_token")
    refreshToken = '';
}
