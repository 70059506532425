




import { ApplicationModule } from '@/store/modules/application'
import {Vue, Component} from 'vue-property-decorator'

@Component({
    name: 'ErrorSnackbar'
})
export default class ErrorSnackbar extends Vue{
    get errorMessage() {
        return ApplicationModule.errorMessage;
    }

    get display() {
        return !!this.errorMessage;
    }
}
