



















import { ApplicationModule } from "@/store/modules/application";
import { UserModule } from "@/store/modules/user";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "NavBar",
})
export default class NavBarVue extends Vue {
  @Prop({default: true}) readonly authenticated!: boolean;

  get athlete() {
    return UserModule.athlete;
  }

  get initialized() {
    return ApplicationModule.initialized;
  }
}
