import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Activity")
export default class GetActivity {
  @JsonProperty("id", Number)
  id = 0;
  @JsonProperty("name", String)
  name = '';
  @JsonProperty("distance", Number)
  distance = 0;
  @JsonProperty("moving_time", Number)
  movingTime = 0;
  @JsonProperty("elapsed_time", Number)
  elapsedTime = 0;
  @JsonProperty("total_elevation_gain", Number)
  totalElevationGain = 0;
  @JsonProperty("type", String)
  type = "";
  @JsonProperty("start_date_local", String)
  startDate = "";
  @JsonProperty("average_speed", Number)
  averageSpeed = 0;
  @JsonProperty("max_speed", Number)
  maxSpeed = 0;

  get activityIcon() {
    if (this.type == "Run") return "mdi-run";
    else if (this.type == "Ride") return "mdi-bike";
    else return "";
  }

  get convertedTime(): string {
    let s = this.movingTime;
    const secs = s % 60;
    s = (s - secs) / 60;
    const mins = s % 60;
    const hrs = (s - mins) / 60;

    return hrs + ":" + mins.toString().padStart(2, '0') + ":" + secs.toString().padStart(2, '0');
  }

  get distanceInKilometers() {
    const distance: number = this.distance / 1000;
    return distance.toFixed(2);
  }

  get activityDate() : string {
    const date = new Date(this.startDate);

    return date.toLocaleDateString('fr-be')
  }
}
