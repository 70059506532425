import { ApplicationModule } from "@/store/modules/application";
import { UserModule } from "@/store/modules/user";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/connect",
    name: "Connect with Strava",
    component: () =>
      import(/* webpackChunkName: "Connect" */ "../views/Connect.vue"),
    meta: { layout: 'unauthenticated' }
  },
  {
    path: "/oauth",
    beforeEnter: (to, from, next) => {
      const code = to.query.code;
      if (code && typeof code == "string") UserModule.exchangeToken(code).then(() => next("/"));
      else if(to.query.error == "access_denied"){
        next('/Connect');
        ApplicationModule.displayError('Access Denied');
      };
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (UserModule.isAuthenticated) {
    if (!UserModule.isTokenExpired) next();
    else {
      console.log('Token expired, refreshing..')
      UserModule.refreshAccessToken().then(() => next());
    }
  } else if (to.path != '/connect' && to.path != '/oauth') next('/connect')
  else next();
})

export default router;
