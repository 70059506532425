




















































































import RankingApi from "@/api/RankingApi";
import Ranking from "@/models/Rankings/Ranking";
import RankingLine from "@/models/Rankings/RankingLine";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  data() {
    return {
      ranking: new Ranking(),
      isLoading: false,
      rankingSort: "totalDistance",
      selectedDates: [""],
      displayDatePicker: false,
    };
  },
  mounted() {
    this.getRanking();
  },
  methods: {
    async getRanking(weekModifier = "") {
      this.isLoading = true;
      const response = await RankingApi.getRanking(
        this.ranking.startDate,
        undefined,
        weekModifier
      );
      this.ranking = response;
      this.sortRankingBy(this.rankingSort);
      this.selectedDates = [];
      this.selectedDates.push(response.startDate, response.endDate);
      this.isLoading = false;
    },
    getTrophyColor(index: number) {
      switch (index) {
        case 0:
          return "#ffb404";
        case 1:
          return "#bec0c1";
        case 2:
          return "#bd993b";
        default:
          break;
      }
    },
    sortRankingBy(field: string) {
      this.rankingSort = field;
      this.ranking.lines.sort(
        (a, b) =>
          (b[field as keyof RankingLine] as number) -
          (a[field as keyof RankingLine] as number)
      );
      var elems = document.querySelectorAll(".active-sort");

      [].forEach.call(elems, function (el: Element) {
        el.classList.remove("active-sort");
      });

      document
        .getElementById(field)
        ?.classList.replace("inactive-sort", "active-sort");
    },
    async selectDates() {
      this.displayDatePicker = false;
      this.isLoading = true;
      const response = await RankingApi.getRanking(
        this.selectedDates[0],
        this.selectedDates[1]
      );
      this.ranking = response;
      this.sortRankingBy(this.rankingSort);
      this.selectedDates = [];
      this.selectedDates.push(response.startDate, response.endDate);
      this.isLoading = false;
    },
  },
});
