import GetActivity from "@/models/Activities/GetActivity";
import PostActivity from "@/models/Activities/PostActivity";
import { UserModule } from "@/store/modules/user";
import { JsonConvert } from "json2typescript";
import api from "./BaseApi";

export default abstract class ActivitiesApi {
    static async getAthleteActivitiesFromStrava(page: number, perPage: number): Promise<GetActivity[]> {
        const response = await api.authenticatedStravaApi.get<GetActivity[]>(`/athlete/activities?page=${page}&per_page=${perPage}`);

        return response.data.map((a) => new JsonConvert().deserializeObject(a, GetActivity));
    }

    static async sendActivitiesToAPI(activities: PostActivity[]) : Promise<void> {
        const athleteId = UserModule.athleteId;
        const data = activities.map(a => {
            a.athleteId = +athleteId;
            return new JsonConvert().serializeObject(a, PostActivity)
        });
        const response = await api.backendApi.post(`/athletes/${athleteId}/activities`, data);

        console.log(response);
    }
}
